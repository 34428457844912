
import { Col, Row } from 'react-flexbox-grid';
import { useFieldArray, Controller } from 'react-hook-form';
import * as React from 'react';
import CustomSelect from '../../../NewComponent/Select';
import Input from '../../../NewComponent/Input';
import { Button, Icon } from '../../../../../../v4/components';
import { getMeasureList, initialDialogItems, measureList, rangeValueCompareListFrom, rangeValueCompareListTo, rangeValueTypeList } from '../config';
import styled from 'styled-components';
import { clone } from '../../../../../../utils/objectProcessor';
import { debouncer } from '../../../../../../utils/formHandlers';

export const Dialog: React.FC<any> = ({ props,
  formParam,
  dialog,
  handleDialogFormSubmit,
  tempList,
  summaryObj,
  displayAlert,
}) => {
  const { handleSubmit, control, errors, reset, clearErrors, watch, setValue, trigger, getValues } = formParam;
  const { element, type } = dialog;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'items',
  });

  const handleRemove = (index) => {
    clearErrors(`items.${index}`);
    remove(index);
  };
  const aliasTitle = tempList?.map(ie => ie.measure?.title);
  const filteredArr = getMeasureList()?.filter(ie => !aliasTitle?.includes(ie.title));
  const allValues = watch();

  const handleAdd = async () => {
    const isValid = await trigger('items');
    if (isValid) {
      append({ ...initialDialogItems, rangeFromValueType: allValues?.items?.[0]?.rangeFromValueType, rangeToValueType: allValues?.items?.[0]?.rangeToValueType, rangeFromValue: null, rangeToValue: null });
    } else {
      // displayAlert(ALERT_TYPE.WARNING, "Please fill all the field to add more slab.");
    }
  };


  const debouncedOnInputChangeFrom = debouncer(({ e, index, param }) => {
    const inputValue = Number(e.target.value);
    let newValue = null;
    if (index > 0) {
      const rangeValueGet = allValues?.items?.[index - 1]?.rangeToValue;
      if (inputValue <= Number(rangeValueGet)) {
        newValue = Number(rangeValueGet);
      }
      else {
        newValue = Number(inputValue);
      }
    }
    else {
      newValue = Number(inputValue);
    }
    setValue(`items[${index}].[${param}]`, newValue);
  }, 1600);

  const debouncedOnInputChangeTo = debouncer(({ e, index, param }) => {
    const inputValue = Number(e.target.value);
    let newValue = null;
    const rangeValueGet = allValues?.items?.[index]?.rangeFromValue;
    if (inputValue <= Number(rangeValueGet)) {
      newValue = Number(rangeValueGet);
    }
    else {
      newValue = Number(inputValue);
    }
    resetSubsequentFields(newValue, index);
  }, 1600);

  const resetSubsequentFields = (newValue, startIndex) => {
    const itemExist = allValues?.items?.[startIndex + 1]?.rangeToValue; //to check more index object in array item is exist 
    if (!(itemExist > 0)) {
      setValue(`items[${startIndex}].rangeToValue`, newValue);
    }
    else {
      const updatedItems = clone([...allValues?.items]); // this is done to reset all items after change in values sequence which all other initial and end value is dependent of
      updatedItems[startIndex] = {
        ...updatedItems[startIndex],
        rangeToValue: newValue,
      };
      const trimmedItems = updatedItems.slice(0, startIndex + 1);
      setValue("items", trimmedItems, { shouldValidate: true });
    }
  };
  return (
    <DialogStyled>
      <form onSubmit={handleSubmit((data: any) => {
        handleDialogFormSubmit({ data, type });
      })}
        id="hook-form-dialog"
        noValidate
      >
        <Row>
          <Col lg={4}>
            <div className="pad-24">
              <Controller
                control={control}
                name="measure"
                render={({ field: { onChange, value } }) => (
                  <>
                    <CustomSelect
                      labelContent="Measure/KPI"
                      placeholder="Select"
                      value={value}
                      options={filteredArr}
                      name="measure"
                      getOptionValue={({ id }) => id}
                      getOptionLabel={({ title }) => title}
                      onChange={(val: any) => {
                        onChange(val);
                        setValue({ items: [{ ...initialDialogItems }] });
                      }}
                      requiredIcon
                      errorMessage={errors?.measure && `${errors?.measure.message}`}
                      isError={errors?.measure}
                      menuPortalTarget={true && document.querySelector("body")}
                    />
                  </>
                )}
              />
            </div>
          </Col>
        </Row>
        <table>
          <thead>
            <tr>
              <th colSpan={3} className="bor-r-1">Range From</th>
              <th colSpan={3} className="bor-r-1">Range To</th>
              <th className='text-right'>Incentive Amount</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {fields?.map?.((item: any, index) => {
              const rangeValueCompareListToFilter = ["GREATER_THAN", "GREATER_THAN_OR_EQUAL"]?.includes(allValues?.items?.[index]?.rangeFromValueCompare) ? rangeValueCompareListTo?.filter(item => item?.value !== allValues?.items?.[index]?.rangeFromValueCompare) : rangeValueCompareListTo;
              return (
                <tr key={item?.id}>
                  <td>
                    <Controller
                      control={control}
                      name={`items[${index}].rangeFromValueType`}
                      render={({ field: { onChange, value } }) => (
                        <>
                          <CustomSelect
                            labelContent=""
                            placeholder="Select"
                            options={rangeValueTypeList}
                            value={rangeValueTypeList?.filter?.(item => item?.value === value)}
                            name={`items[${index}].rangeFromValueType`}
                            getOptionValue={({ value }) => value}
                            getOptionLabel={({ type }) => type}
                            onChange={(val: any) => {
                              onChange(val?.value);
                              if (index === 0) {
                                fields.forEach((_, i) => {
                                  if (i !== 0) {
                                    setValue(`items[${i}].rangeFromValueType`, val?.value);
                                    setValue(`items[${i}].rangeToValueType`, val?.value);
                                  }
                                });
                              }
                              setValue(`items[${index}].rangeFromValue`, null);
                              setValue(`items[${index}].rangeToValue`, null);
                              setValue(`items[${index}].rangeToValueType`, val?.value);
                            }}
                            requiredIcon
                            disabled={index > 0}
                            errorMessage={errors?.items?.[index]?.rangeFromValueType?.message}
                            isError={!!errors?.items?.[index]?.rangeFromValueType}
                            menuPortalTarget={true && document.querySelector("body")}
                            size32
                          />
                        </>
                      )}
                    />
                  </td>
                  <td>
                    <Controller
                      control={control}
                      name={`items[${index}].rangeFromValueCompare`}
                      render={({ field: { onChange, value } }) => (
                        <>
                          <CustomSelect
                            labelContent=""
                            placeholder="Select"
                            options={rangeValueCompareListFrom}
                            value={rangeValueCompareListFrom?.filter?.(item => item?.value === value)}
                            name={`items[${index}].rangeFromValueCompare`}
                            getOptionValue={({ value }) => value}
                            getOptionLabel={({ title }) => title}
                            onChange={(val: any) => onChange(val?.value)}
                            requiredIcon
                            errorMessage={errors?.items?.[index]?.rangeFromValueCompare?.message}
                            isError={!!errors?.items?.[index]?.rangeFromValueCompare}
                            menuPortalTarget={true && document.querySelector("body")}
                            size32
                          />
                        </>
                      )}
                    />
                  </td>
                  <td className="bor-r-1 value-td">
                    <Controller
                      control={control}
                      name={`items[${index}].rangeFromValue`}
                      render={({ field: { onChange, value } }) => (
                        <>
                          <Input
                            value={allValues?.items?.[index]?.rangeFromValue}
                            type="number"
                            name={`items[${index}].rangeFromValue`}
                            placeholder={"Value"}
                            onChange={(e) => {
                              onChange(e)
                              setValue(`items[${index}].rangeToValue`, null);
                              debouncedOnInputChangeFrom({ e, index, param: "rangeFromValue" });
                            }}
                            isError={!!errors?.items?.[index]?.rangeFromValue}
                            requiredIcon
                            errorMessage={
                              errors?.items?.[index]?.rangeFromValue?.message || "Required"
                            }
                          />
                          <span className="para">{rangeValueTypeList?.find(ite => ite?.value === allValues?.items?.[index]?.rangeFromValueType)?.label}</span>
                        </>
                      )}
                    />
                  </td>

                  <td>
                    <Controller
                      control={control}
                      name={`items[${index}].rangeToValueType`}
                      render={({ field: { onChange, value } }) => (
                        <>
                          <CustomSelect
                            labelContent=""
                            placeholder="Select"
                            options={rangeValueTypeList}
                            value={rangeValueTypeList?.filter?.(item => item?.value === value)}
                            name={`items[${index}].rangeToValueType`}
                            getOptionValue={({ value }) => value}
                            getOptionLabel={({ type }) => type}
                            onChange={(val: any) => {
                              onChange(val?.value);
                              if (index === 0) {
                                fields.forEach((_, i) => {
                                  if (i !== 0) {
                                    setValue(`items[${i}].rangeToValueType`, val?.value);
                                    setValue(`items[${i}].rangeFromValueType`, val?.value);
                                  }
                                });
                              }
                              setValue(`items[${index}].rangeToValue`, null);
                              setValue(`items[${index}].rangeFromValueType`, val?.value);
                            }}

                            disabled={index > 0}
                            requiredIcon
                            errorMessage={errors?.items?.[index]?.rangeToValueType?.message}
                            isError={!!errors?.items?.[index]?.rangeToValueType}
                            menuPortalTarget={true && document.querySelector("body")}
                            size32
                          />
                        </>
                      )}
                    />
                  </td>
                  <td>
                    <Controller
                      control={control}
                      name={`items[${index}].rangeToValueCompare`}
                      render={({ field: { onChange, value } }) => (
                        <>
                          <CustomSelect
                            labelContent=""
                            placeholder="Select"
                            options={rangeValueCompareListToFilter}
                            value={rangeValueCompareListToFilter?.filter?.(item => item?.value === value)}
                            name={`items[${index}].rangeToValueCompare`}
                            getOptionValue={({ value }) => value}
                            getOptionLabel={({ title }) => title}
                            onChange={(val: any) => onChange(val?.value)}
                            requiredIcon
                            errorMessage={errors?.items?.[index]?.rangeToValueCompare?.message}
                            isError={!!errors?.items?.[index]?.rangeToValueCompare}
                            menuPortalTarget={true && document.querySelector("body")}
                            size32
                          />
                        </>
                      )}
                    />
                  </td>
                  <td className="bor-r-1 value-td">
                    <Controller
                      control={control}
                      name={`items[${index}].rangeToValue`}
                      render={({ field: { onChange, value } }) => (
                        <>
                          <Input
                            value={allValues?.items?.[index]?.rangeToValue}
                            type="number"
                            name={`items[${index}].rangeToValue`}
                            placeholder={"Value"}
                            onChange={(e) => {
                              onChange(e)
                              debouncedOnInputChangeTo({ e, index, param: "rangeToValue" });
                            }}
                            isError={!!errors?.items?.[index]?.rangeToValue}
                            requiredIcon
                            errorMessage={
                              errors?.items?.[index]?.rangeToValue?.message || 'Required'
                            }
                          />
                          <span className="para">{rangeValueTypeList?.find(ite => ite?.value === allValues?.items?.[index]?.rangeToValueType)?.label}</span>
                        </>
                      )}
                    />
                  </td>
                  <td className="text-right amount-input">
                    <Controller
                      control={control}
                      name={`items[${index}].amount`}
                      render={({ field: { onChange, value } }) => (
                        <>
                          <Input
                            value={value}
                            type="number"
                            name={`items[${index}].amount`}
                            placeholder="Amount"
                            onChange={(e) => {
                              const inputValue = Number(e.target.value);
                              if (inputValue > 0) {
                                setValue(`items[${index}].amount`, inputValue);
                                onChange(inputValue)
                              }
                              else {
                                setValue(`items[${index}].amount`, null);
                                onChange(null)
                              }
                            }}
                            isError={!!errors?.items?.[index]?.amount}
                            constantValue={value}
                            requiredIcon
                            errorMessage={
                              errors?.items?.[index]?.amount?.message || 'Required'
                            }
                          />
                          <span className="para">NPR</span>
                        </>
                      )}
                    />
                  </td>
                  <td className="text-right">
                    <Button
                      type="button"
                      onClick={() => handleRemove(index)}
                      disabled={fields.length === 1} // Prevent removing the last row
                    >
                      <Icon iconName="trash" />
                    </Button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
        <Row>
          <div className="right-note"><span><b> <Icon iconName="info-circle" /> Note:</b>Please start with the lower range first</span></div>
          <Col lg={12} className="btn-submit">
            <Button
              secondary
              small
              outlined
              iconName="plus"
              type="button"
              title="Add Slab"
              onClick={handleAdd}
              className="outlined"
            />

          </Col>
        </Row>
      </form>
    </DialogStyled>
  );
};

export const DialogStyled = styled.div`
    .form-input,
    .form-select-input{
      margin-bottom:0;
      .zindex-2__control{
        background-color:#F1F2F5;
      }
      .form-select-border .zindex-2__control{
        background-color:unset !important;
      }
    }
    input{
      height:35px;
      width:86px;
      font-size:14px;
      text-align:right;
    }
    .amount-input{
      position:relative;
      .para{
        position: absolute;
        top: 16px;
        left: 16px;
        color:#A7A9BC;
      }
       input{
      width:155px;
      padding-left:36px;
    }
  }
      table {
      tr {
        height:40px !important;
        border-bottom:0;
        td,th{
          padding: 6px !important;
          &:first-child{
            padding: 6px 0  6px 24px !important;
          }
        }
          td{
            border-bottom:0;
            &.value-td{
              position: relative;
              .form-input{
                margin-bottom:0;
              }
              input{
                padding-right:24px;
              }
              .para{
                position: absolute;
                top: 16px;
                right: 16px;
                color:#A7A9BC;
              }
            }
          }
          &.last-child td{
          border-bottom:1px solid #e7e7ed;
        }
        }
      .form-error{
      display:none;
    }
    .form-select-input{
      width:88px;
      margin-bottom:0;
      input{margin-bottom:0; }
      label{display:none;}
    }
  }
  .error-msg {
    input{
    border:1px solid  #ea7179;
    }
  }
  input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.right-note{
  display: flex;
    padding: 16px 32px 16px 32px;
    width: 100%;
    font-size: 12px;
    color:#767171;
    .icon{
      height: 12px;
      width:12px;
    }
}
.btn-submit{
  padding:0 24px 24px 24px;
}
`
